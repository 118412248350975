import { reactive, readonly } from 'vue'
import * as State from '@/types/App/State'
import { useEnvironment } from 'vue-page-components'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export abstract class Store<T extends State.StateType> {
    protected state: State.StateType | undefined

    constructor() {
        const { isSSR } = useEnvironment()

        this.setState(isSSR() ? this.data() : this.hydrate())
    }

    public setState(state: State.StateType): void {
        this.state = reactive(state)
    }

    public getState(): State.StateType {
        return readonly(this.state || {}) as State.StateType
    }

    protected abstract key(): string

    protected abstract data(): State.StateType

    protected hydrate(): State.StateType {
        return window?.__STATE__ ? (window?.__STATE__[this.key()] as State.StateType) : this.data()
    }
}
