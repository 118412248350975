import { createMemoryHistory, createWebHistory, createRouter, Router } from 'vue-router'
import { routes } from './routes'
import { useEnvironment } from 'vue-page-components'
import { useRouting } from '@/compositions/useRouting'

export function createApplicationRouter(): Router {
    const { beforeEach, afterEach } = useRouting()
    const { isSSR } = useEnvironment()

    const router = createRouter({
        history: isSSR() ? createMemoryHistory() : createWebHistory(),
        routes,
    })
    router.beforeEach(beforeEach)
    router.afterEach(afterEach)

    return router
}
