import smoothscroll from 'smoothscroll-polyfill'
import { createApplication } from './apps/app.vue'

import './assets/scss/app.scss'
import { useSentryBrowser } from './compositions/useSentryBrowser'

const { initSentry, captureException } = useSentryBrowser()

smoothscroll.polyfill()

initSentry()

try {
    const { app, router } = createApplication()

    ;(async (r, a) => {
        await r.isReady()
        a.mount('#app', true)
    })(router, app)
} catch (err: unknown) {
    captureException(err as Error)
}
