import { computed } from 'vue'
import { HeadMeta, MetaTag } from '@/types/Page/Meta'
import { useEnvironment } from 'vue-page-components'
import { usePage } from '@/compositions/usePage'
import { useImage } from './useImage'

export function useMeta() {
    const { isSSR } = useEnvironment()
    const { page, absoluteUrl } = usePage()
    const { image } = useImage()

    const content = (name: string, content: string) => ({ name, content })
    const property = (property: string, content: string) => ({ property, content })
    const preload = (href: string, as: string) => ({ rel: 'preload', href, as })
    const rel = (rel: string, href: string) => ({ rel, href })
    const attributes = (attributes: MetaTag): MetaTag => ({
        ...(isSSR() && { ssr: true }),
        ...attributes,
    })
    const head = computed<HeadMeta>(() => {
        return {
            title: page.value.meta_title,
            meta: [
                content('description', page.value.meta_description),
                content('robots', page.value.robots),
                content('twitter:title', page.value.meta_title),
                content('twitter:description', page.value.meta_description),
                content('twitter:image', image(page.value)),
                content('twitter:creator', '@phpfyi'),
                content('twitter:site', page.value.twitter_site),
                content('twitter:card', page.value.twitter_card),
                property('og:title', page.value.meta_title),
                property('og:description', page.value.meta_description),
                property('og:type', page.value.og_type),
                property('og:site_name', page.value.og_sitename),
                property('og:locale', page.value.og_locale),
                property('og:url', absoluteUrl(page.value)),
                property('og:image', image(page.value)),
            ],
            links: [rel('canonical', absoluteUrl(page.value))],
            preload: [
                ...(page.value.preload || []).map((image: string) => preload(image, 'image')),
            ],
        }
    })
    return {
        content,
        property,
        preload,
        rel,
        attributes,
        head,
    }
}
