import { createApp, createSSRApp } from 'vue'
import { Router } from 'vue-router'
import { Request } from 'express'

import { createApplicationRouter } from '../routing/router'
import { useSentryBrowser } from '@/compositions/useSentryBrowser'

import App from '../components/App/App.vue'
import AppBreadcrumbs from '../components/App/AppBreadcrumbs.vue'
import AppComponents from '../components/App/AppComponents.vue'
import AppFooter from '../components/App/AppFooter.vue'
import AppHeader from '../components/App/AppHeader.vue'
import AppMeta from '../components/App/AppMeta.vue'
import AppSchema from '../components/App/AppSchema.vue'
import BaseIcon from '../components/Assets/Icon/BaseIcon.vue'
import IconLogo from '../components/Assets/Logo/IconLogo.vue'
import IconLogoLarge from '../components/Assets/Logo/IconLogoLarge.vue'
import SvgWrapper from '../components/Assets/Icon/SvgWrapper.vue'
import AppButton from '../components/Button/AppButton.vue'
import BackButton from '../components/Button/BackButton.vue'
import HamburgerButton from '../components/Button/HamburgerButton.vue'
import AppDivider from '../components/Card/Divider/AppDivider.vue'
import ConsentPopup from '../components/Feature/Consent/ConsentPopup.vue'
import TransitionFade from '../components/Feature/Transition/TransitionFade.vue'

import {
    useEnvironment,
    BaseHeading,
    BaseText,
    BaseImage,
    BaseExternalLink,
    BaseLink,
    CheckboxInput,
    FormBase,
    FormMessages,
    EmailInput,
    InputLabel,
    PhoneNumberInput,
    SelectInput,
    SubmitInput,
    TextareaInput,
    TextInput,
} from 'vue-page-components'

export function createApplication(req?: Request) {
    const { isSSR } = useEnvironment()
    const { captureException } = useSentryBrowser()

    const app = isSSR() ? createSSRApp(App, { cookies: req?.headers?.cookie }) : createApp(App)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    app.config.errorHandler = (err, instance, info) => captureException(err as Error)

    const router: Router = createApplicationRouter()

    app.use(router)

    app.component('AppBreadcrumbs', AppBreadcrumbs)
    app.component('AppComponents', AppComponents)
    app.component('AppFooter', AppFooter)
    app.component('AppHeader', AppHeader)
    app.component('AppMeta', AppMeta)
    app.component('AppSchema', AppSchema)
    app.component('BaseIcon', BaseIcon)
    app.component('IconLogo', IconLogo)
    app.component('IconLogoLarge', IconLogoLarge)
    app.component('SvgWrapper', SvgWrapper)
    app.component('AppButton', AppButton)
    app.component('BackButton', BackButton)
    app.component('HamburgerButton', HamburgerButton)
    app.component('AppDivider', AppDivider)
    app.component('ConsentPopup', ConsentPopup)
    app.component('TransitionFade', TransitionFade)

    app.component('BaseHeading', BaseHeading)
    app.component('BaseText', BaseText)
    app.component('BaseImage', BaseImage)
    app.component('BaseExternalLink', BaseExternalLink)
    app.component('BaseLink', BaseLink)
    app.component('CheckboxInput', CheckboxInput)
    app.component('FormBase', FormBase)
    app.component('FormMessages', FormMessages)
    app.component('EmailInput', EmailInput)
    app.component('InputLabel', InputLabel)
    app.component('PhoneNumberInput', PhoneNumberInput)
    app.component('SelectInput', SelectInput)
    app.component('SubmitInput', SubmitInput)
    app.component('TextareaInput', TextareaInput)
    app.component('TextInput', TextInput)

    //app.config.devtools = true
    //app.config.productionTip = false

    return { app, router }
}
