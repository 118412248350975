import { computed } from 'vue'
import { AxiosError, AxiosInstance } from 'axios'

import * as Api from '@/types/App/Api'
import { ArticleSchema } from '@/types/Page/Schema'
import { Page } from '@/types/Model/Page'

import { useAxios } from './useAxios'
import { useStore } from './useStore'
import { useUrl } from './useUrl'
import { useSentryBrowser } from './useSentryBrowser'

export function usePage() {
    const { fetch, fetching } = useAxios()
    const { pageStore, pageState } = useStore()
    const { url } = useUrl()
    const { captureException } = useSentryBrowser()

    const page = computed(() => pageState.page as Page)
    const schema = computed(() => pageState.schema as ArticleSchema)
    const breadcrumbs = computed(() => pageState.page?.breadcrumbs || [])
    const components = computed(() => pageState.page?.components || [])

    const show = async (slug: string): Promise<Api.ApiResponse> => {
        const endpoint = `${process.env.VUE_APP_API_URL}api/pages/page?path=${slug}`

        const { body, exception } = (await fetch(
            async (client: AxiosInstance) => await client.get(endpoint)
        )) as Api.Response

        pageStore.page = body?.data as unknown as Page

        if (exception) {
            if ((exception as AxiosError)?.response?.status === 404) {
                // @todo 500 page
                pageStore.page = notFound()
            } else {
                captureException(exception)
            }
        }
        return body
    }
    const notFound = () => {
        return {
            title: 'Page not found',
            meta_title: 'Page not found',
            robots: 'noindex,nofollow',
            canonical: 'not-found',
        } as Page
    }
    const absoluteUrl = (page: Page): string => url(page.canonical)
    const publishedAtDate = (page: Page): string => new Date(page.published_at).toDateString()
    const preloadImage = (image: string) => (pageStore.preload = image)

    return {
        fetching,
        page,
        schema,
        breadcrumbs,
        components,
        show,
        absoluteUrl,
        publishedAtDate,
        preloadImage,
    }
}
