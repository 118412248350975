export const COOKIES = <const>{
    SETTINGS: 'phpfyi_settings',
    TRACKING_ID: 'phpfyi_cid',
}
export type Cookie = typeof COOKIES[keyof typeof COOKIES]

export const HEADER_CONSENT_COOKIE =
    'phpfyi_settings={"preferences":true,"marketing":true,"statistics":true}'

export const TEMPLATE_INDEX = 'index.html'
export const TEMPLATE_INDEX_POPUP = 'index-popup.html'
