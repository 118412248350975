import { ArticleSchema } from '@/types/Page/Schema'
import { Page } from '@/types/Model/Page'
import { useUrl } from './useUrl'

export function useSchema() {
    const article = (page: Page): ArticleSchema => {
        const { imageUrls } = useUrl()

        return {
            '@context': 'https://schema.org',
            '@type': 'Article',
            headline: page.title,
            image: imageUrls(page.images),
            datePublished: page.published_at.slice(0, -8),
            dateModified: page.published_at.slice(0, -8), // @todo updated_at
            author: {
                '@type': 'Person',
                name: 'Andrew Mc Cormack',
                url: process.env.VUE_APP_BASE_URL,
            },
        }
    }
    return {
        article,
    }
}
