import { reactive } from 'vue'
import { AxiosInstance } from 'axios'
import { ref } from 'vue'
import { useAxios } from './useAxios'
import * as Api from '@/types/App/Api'
import * as Contact from '@/types/Form/Contact'

export function useContact() {
    const { fetch, fetching } = useAxios()

    const endpoint = `${process.env.VUE_APP_API_URL}api/pages/contact`
    const errors = ref({} as Contact.FormErrors)
    const success = ref(false)
    const form: Contact.Form = reactive({
        name: '',
        email: '',
        phone: '',
        entity: undefined,
        company: '',
        address: '',
        town: '',
        county: '',
        postcode: '',
        country: '',
        type: undefined,
        content: '',
        terms: false,
        mailing: false,
    })
    const submit = async (data: Contact.Form): Promise<Api.ApiResponse> => {
        const { body } = (await fetch(
            async (client: AxiosInstance) => await client.post(endpoint, data)
        )) as Api.Response
        errors.value = (body.errors || {}) as Contact.FormErrors
        success.value = body.success || false

        return body
    }
    return {
        submit,
        fetching,
        errors,
        success,
        form,
    }
}
