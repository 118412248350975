export const COLORS = <const>{
    BLACK: 'black',
    WHITE: 'white',
    GREY_XXL: 'grey-xxl',
    GREY_XL: 'grey-xl',
    GREY_L: 'grey-l',
    GREY_M: 'grey-m',
    GREY_D: 'grey-d',
    GREY_XD: 'grey-xd',
    GREY_XXD: 'grey-xxd',
    GREY_XXXD: 'grey-xxxd',
    CYAN_BRIGHT: 'cyan-bright',
    BLUE_BRIGHT: 'blue-bright',
    BLUE_MEDIUM: 'blue-medium',
    BLUE_DARK: 'blue-dark',
    GREEN_BRIGHT: 'green-bright',
    GREEN_DARK: 'green-dark',
    GREEN_XDARK: 'green-xdark',
}

export type Color = typeof COLORS[keyof typeof COLORS]

export const HEX = <const>{
    BLACK: '#000',
    WHITE: '#FFF',
    GREY_XXL: '#F7F7F7',
    GREY_XL: '#ECECEC',
    GREY_L: '#CCC',
    GREY_M: '#999999',
    GREY_D: '#666666',
    GREY_XD: '#333333',
    GREY_XXD: '#232323',
    GREY_XXXD: '#171717',
    CYAN_BRIGHT: '#04fff2',
    BLUE_BRIGHT: '#00e3ff',
    BLUE_MEDIUM: '#00708E',
    BLUE_DARK: '#003C4E',
    GREEN_BRIGHT: '#05c63a',
    GREEN_DARK: '#00232b',
    GREEN_XDARK: '#001419',
}

export type HexColor = typeof HEX[keyof typeof HEX]

export const TAGS = <const>{
    ROLE: 'black',
    PROJECT: 'blue-medium',
    SKILL: 'blue-bright',
    PACKAGE: 'blue-dark',
    EVENT: 'green-bright',
    COURSE: 'green-dark',
}

export type TagName = keyof typeof TAGS
export type TagTheme = typeof TAGS[TagName]
