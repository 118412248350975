import { appStore } from '@/store/app'
import { pageStore } from '@/store/page'
import * as State from '@/types/App/State'

export function useStore() {
    const appState = appStore.getState() as State.AppState
    const pageState = pageStore.getState() as State.PageState

    return {
        appStore,
        appState,
        pageStore,
        pageState,
    }
}
