import { CareerEvent } from '@/types/Data/Career'
import { TAGS } from '@/constants/Colors'

export const events: Array<CareerEvent> = [
    {
        year: 1985,
        description: 'Born in Ireland',
    },
    {
        year: 1990,
        description: 'Started drawing',
        category: TAGS.SKILL,
    },
    {
        year: 2000,
        description: 'Drew my self portrait',
        category: TAGS.PROJECT,
        image: 'project-self-portrait.webp',
    },
    {
        year: 2002,
        description: 'Drew my abstract faces piece',
        category: TAGS.PROJECT,
        image: 'project-faces.webp',
    },
    {
        year: 2001,
        description: 'Started to learn Photoshop',
        category: TAGS.SKILL,
    },
    {
        year: 2004,
        description: 'Completed a course in Art and Design (1 year)',
        category: TAGS.COURSE,
    },
    {
        year: 2009,
        description: 'Started to learn HTML',
        category: TAGS.SKILL,
    },
    {
        year: 2009,
        description: 'Built my first portfolio website',
        category: TAGS.PROJECT,
    },
    {
        year: 2010,
        description: 'Started to learn JavaScript',
        category: TAGS.SKILL,
    },
    {
        year: 2010,
        description: 'Started to learn PHP',
        category: TAGS.SKILL,
    },
    {
        year: 2010,
        description: 'Started to learn Google Analytics',
        category: TAGS.SKILL,
    },
    {
        year: 2011,
        description: 'Completed my Bachelor of Arts in Graphic Design degree (3 years)',
        category: TAGS.COURSE,
    },
    {
        date: 'September',
        year: 2011,
        description: 'Built my website Evony Advanced Guide',
        category: TAGS.PROJECT,
    },
    {
        year: 2012,
        description: 'Completed my Bachelor of Arts in Digital Media honours degree (1 year)',
        category: TAGS.COURSE,
    },
    {
        year: 2012,
        description: 'Built a website for the local county mountain resue team',
        category: TAGS.PROJECT,
    },
    {
        date: 'July',
        year: 2012,
        description: 'Built my website Stronghold Kingdoms Advanced Guide',
        category: TAGS.PROJECT,
    },
    {
        year: 2013,
        description: 'Built my own PHP CMS framework',
        category: TAGS.PACKAGE,
    },
    {
        year: 2013,
        description: 'Worked for a luxury watch ecommerce company as a Web Developer (2 years)',
        category: TAGS.ROLE,
    },
    {
        year: 2013,
        description: 'Started to learn Google Adwords',
        category: TAGS.SKILL,
    },
    {
        year: 2013,
        description: 'Started to learn Google Tag Manager',
        category: TAGS.SKILL,
    },
    {
        date: 'September',
        year: 2013,
        description: 'Built a website for a luxury watch retailer',
        category: TAGS.PROJECT,
    },
    {
        year: 2015,
        description: 'Worked for a digital agency as a Web Developer (5 years)',
        category: TAGS.ROLE,
    },
    {
        year: 2015,
        description: 'Started to learn SilverStripe (4 years)',
        category: TAGS.SKILL,
    },
    {
        date: 'August',
        year: 2015,
        description: 'Created SilverStripe SEO open source package',
        category: TAGS.PACKAGE,
    },
    {
        date: 'August',
        year: 2015,
        description: 'Created SilverStripe Tag Manager open source package',
        category: TAGS.PACKAGE,
    },
    {
        year: 2015,
        description: 'Attended Laracon EU in Amsterdam',
        category: TAGS.EVENT,
        image: 'attended-laracon.webp',
    },
    {
        year: 2016,
        description: 'Started to learn Laravel',
        category: TAGS.SKILL,
    },
    {
        year: 2016,
        description: 'Started to learn React',
        category: TAGS.SKILL,
    },
    {
        year: 2016,
        description: 'Built the back end for a worldwide science frestival',
        category: TAGS.PROJECT,
    },
    {
        date: 'September',
        year: 2016,
        description: 'Created SilverStripe Block page open source package',
        category: TAGS.PACKAGE,
    },
    {
        date: 'September',
        year: 2016,
        description: 'Attended Start Up Bus, a hackathon across Europe for 7 days',
        category: TAGS.EVENT,
        image: 'attended-startup-bus.webp',
    },
    {
        date: 'September',
        year: 2016,
        description: 'Built Sqill, a social media app',
        category: TAGS.PROJECT,
    },
    {
        date: '6 September',
        year: 2016,
        description:
            'Attended and pitched at Pirate Summit, a 2 day conference in Cologne, Germany',
        category: TAGS.EVENT,
        image: 'attended-pirate-summit.webp',
    },
    {
        date: '25 September',
        year: 2016,
        description: 'Attended StripeCon EU in Ljubljana, Slovenia and gave 2 talks',
        category: TAGS.EVENT,
        image: 'attended-stripecon.webp',
    },
    {
        year: 2017,
        description: 'Started to learn Vue',
        category: TAGS.SKILL,
    },
    {
        year: 2017,
        description: 'Started to learn Angular',
        category: TAGS.SKILL,
    },
    {
        year: 2017,
        description: 'Started to learn TypeScript',
        category: TAGS.SKILL,
    },
    {
        date: 'January',
        year: 2017,
        description: 'Completed my Google Adwords Fundamentals certification',
        category: TAGS.COURSE,
    },
    {
        date: 'July',
        year: 2017,
        description: 'Completed my Google Analytics Individual Qualification certification',
        category: TAGS.COURSE,
    },
    {
        date: 'July',
        year: 2017,
        description: 'Completed my Google Tag Manager Fundamentals certification',
        category: TAGS.COURSE,
    },
    {
        date: 'July',
        year: 2017,
        description: 'Built a website for a luxury watch retailer',
        category: TAGS.PROJECT,
    },
    {
        date: 'September',
        year: 2017,
        description: 'Attended UK agency awards in London',
        category: TAGS.EVENT,
    },
    {
        date: 'October',
        year: 2017,
        description: 'Attended wirehive 100 agency awards in Southhampton',
        category: TAGS.EVENT,
        image: 'attended-wirehive-100.webp',
    },
    {
        year: 2018,
        description: 'Built the back end for a website for a leading environmental systems company',
        category: TAGS.PROJECT,
    },
    {
        date: 'October',
        year: 2018,
        description: 'Created SilverStripe Elastic Search open source package',
        category: TAGS.PACKAGE,
    },
    {
        date: 'November',
        year: 2018,
        description: 'Attended Google Partner Summit in San Francisco',
        category: TAGS.EVENT,
        image: 'attended-google-partner-summit.webp',
    },
    {
        year: 2018,
        description: 'Built my own CSS Frameowrk',
        category: TAGS.PACKAGE,
    },
    {
        year: 2019,
        description:
            'Built the back end for the leading UK consumer right government organisation website',
        category: TAGS.PROJECT,
    },
    {
        year: 2019,
        description: 'Worked for a sharing economy toy company as a Lead Developer',
        category: TAGS.ROLE,
    },
    {
        year: 2019,
        description: 'Started to learn Nuxt',
        category: TAGS.SKILL,
    },
    {
        year: 2020,
        description: 'Started to learn Node',
        category: TAGS.SKILL,
    },
    {
        year: 2020,
        description: 'Built my new portfolio website',
        category: TAGS.PROJECT,
    },
]
