import { SharerNetwork } from '@/types/App/Social'

export const sharer = (url: string, title: string): Array<SharerNetwork> => {
    return [
        {
            id: 'facebook',
            name: 'Facebook',
            endpoint: 'http://www.facebook.com/sharer.php',
            params: {
                u: url,
            },
        },
        {
            id: 'twitter',
            name: 'Twitter',
            endpoint: 'https://twitter.com/share',
            params: {
                url: url,
                via: 'phpfyi',
                text: title,
                hashtags: 'webdevelopment', // @todo add CMS field
            },
        },
        {
            id: 'pinterest',
            name: 'Pinterest',
            endpoint: 'https://pinterest.com/pin/create/bookmarklet/',
            params: {
                url: url,
                media: 'image URL',
                is_video: 'false',
                description: title,
            },
        },
        {
            id: 'linkedin',
            name: 'LinkedIn',
            endpoint: 'https://www.linkedin.com/shareArticle/',
            params: {
                url: url,
                title: title,
            },
        },
        {
            id: 'email',
            name: 'Email',
            endpoint: 'mailto:',
            params: {
                body: url,
                subject: title,
            },
        },
    ]
}
