import { Consent } from 'vue-page-components/lib/types'
import * as State from '@/types/App/State'
import { Store } from '@/store'

class AppStore extends Store<State.AppState> {
    protected declare state: State.AppState

    protected key(): string {
        return 'app'
    }

    protected data(): State.AppState {
        return {
            pageDepth: 1,
            menu: false,
            loading: false,
            wireframe: false,
            consent: null,
        }
    }

    set pageDepth(pageDepth: number) {
        this.state.pageDepth = pageDepth
    }

    set menu(menu: boolean) {
        this.state.menu = menu
    }

    set loading(toggle: boolean) {
        this.state.loading = toggle
    }

    set wireframe(toggle: boolean) {
        this.state.wireframe = toggle
    }

    set consent(consent: Consent.Cookie | null) {
        this.state.consent = consent
    }
}
export const appStore: AppStore = new AppStore()
