import { Store } from '@/store'
import { Page } from '@/types/Model/Page'
import * as State from '@/types/App/State'
import { useSchema } from '@/compositions/useSchema'

const { article } = useSchema()

class PageStore extends Store<State.PageState> {
    protected declare state: State.PageState

    protected key(): string {
        return 'page'
    }

    protected data(): State.PageState {
        return {
            page: null,
            schema: {},
        }
    }

    public setState(state: State.StateType): void {
        super.setState(state)

        if (this.state.page) {
            this.page = this.state.page
        }
    }

    set page(page: Page) {
        this.state.page = new Page(page || {})
        this.state.schema = Number(this.state.page.parent_id) === 4 ? article(this.state.page) : {}
        this.state.page.preload = []
    }

    set preload(image: string) {
        this.state.page?.preload?.push(image)
    }
}
export const pageStore: PageStore = new PageStore()
