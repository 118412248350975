import { Form } from 'vue-page-components/lib/types'

export const entities: Array<Form.SelectOption> = [
    {
        title: 'Individual',
        value: 1,
    },
    {
        title: 'Company',
        value: 2,
    },
    {
        title: 'Other',
        value: 3,
    },
]
export const types: Array<Form.SelectOption> = [
    {
        title: 'Project',
        value: 1,
    },
    {
        title: 'Report site error',
        value: 2,
    },
    {
        title: 'Sales',
        value: 3,
    },
    {
        title: 'Other',
        value: 4,
    },
]
