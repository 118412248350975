import { Page } from '@/types/Model/Page'
import { useUrl } from './useUrl'

export function useImage() {
    const { imageUrls } = useUrl()

    const image = (page: Page, size?: number): string => {
        const parts = (imageUrls(page.images)[0] || '').split('.')
        const extension = parts.pop()

        return [parts.join('.'), size ? `-${size}.` : '.', extension].join('')
    }
    return {
        image,
    }
}
