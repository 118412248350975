// import '@sentry/tracing' @todo

export function useSentry(sentry: Record<string, any>) {
    const initSentry = () => {
        sentry.init({
            dsn: process.env.VUE_APP_SENTRY_DSN,
            environment: process.env.NODE_ENV || 'production',
            ...(process.env.VUE_APP_SENTRY_TRACING === 'true'
                ? {
                      tracesSampleRate: 0.1,
                      integrations: [
                          ...[process.env.VUE_APP_SENTRY_TRACING === 'true' ? [initTracing()] : []],
                      ],
                  }
                : {}),
        })
    }
    const initTracing = () => new sentry.Integrations.Http({ tracing: true })

    const captureException = (e: Error) =>
        process.env.NODE_ENV === 'production' ? sentry.captureException(e) : console.error(e)

    const captureAsyncTransaction = async (
        op = 'test',
        name = 'App Boot',
        cb: () => Promise<unknown>,
        capture: (e: Error) => unknown
    ) => {
        const transaction = sentry.startTransaction({
            op,
            name,
        })
        try {
            await cb()
        } catch (e: unknown) {
            capture(e as Error)
        } finally {
            transaction.finish()
        }
    }
    return {
        initSentry,
        initTracing,
        captureException,
        captureAsyncTransaction,
    }
}
