/* eslint-disable @typescript-eslint/no-unused-vars */
import { nextTick } from 'vue'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import {
    useEnvironment,
    useConsent,
    useGoogleTagManager,
    useScroll,
    useDOM,
} from 'vue-page-components'
import { Consent } from 'vue-page-components/lib/types'

import { COOKIES } from '@/constants/Cookies'

import { useApp } from '@/compositions/useApp'
import { usePage } from '@/compositions/usePage'
import { usePrism } from '@/compositions/usePrism'

export function useRouting() {
    const { pageDepth, incrementPageDepth, setConsentState, toggleLoading, toggleMenu } = useApp()
    const { show, page } = usePage()
    const { isSSR } = useEnvironment()
    const { bootGoogleTagManager, pushData, trackPage } = useGoogleTagManager()
    const { getConsentCookie } = useConsent(COOKIES.SETTINGS)
    const { removeNodes } = useDOM()

    const beforeEach = async (
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
        next: NavigationGuardNext
    ) => {
        if (!isSSR()) {
            toggleLoading(true)

            if (from.name) {
                incrementPageDepth()
            }
            await onPage(to, from)

            if (!page.value) {
                await show(to.path as string)
            }
            trackPage(to.fullPath, document.title)
        }
        next()
    }
    const afterEach = (to: RouteLocationNormalized, _from: RouteLocationNormalized) => {
        if (!isSSR()) {
            toggleLoading(false)
            toggleMenu(false)
            scrollToPage(to)

            page.value.parent_id === 4 && bootPrism()
        }
    }
    const onPage = async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
        if (pageDepth.value === 1) {
            bootGoogleTagManager()
            bootConsent()
        }
        if (to.name !== 'page-not-found' && from.name && from.path !== to.path) {
            await show(to.path as string)
        }
        if (pageDepth.value === 2) {
            removeNodes('[ssr="true"]')
        }
    }
    const bootConsent = () => {
        const consent = getConsentCookie()
        setConsentState(consent as Consent.Cookie)
        if (consent) {
            pushData({ consent })
        }
    }
    const bootPrism = () => {
        const { Prism } = usePrism()

        nextTick(() => setTimeout(() => Prism.highlightAll(), 0))
    }
    const scrollToPage = (to: RouteLocationNormalized) => {
        const { scrollTo, scrollToElement } = useScroll()

        to.hash ? setTimeout(() => scrollToElement(to.hash, 60), 0) : scrollTo()
    }
    return {
        beforeEach,
        afterEach,
    }
}
