import { Link } from 'vue-page-components/lib/types'

const navigation: Array<Link.BaseLinkProps> = [
    {
        to: { name: 'expertise-landing' },
        text: 'Expertise',
    },
    {
        to: { name: 'career' },
        text: 'Career',
    },
    {
        to: { name: 'articles' },
        text: 'Articles',
    },
    {
        to: { name: 'about' },
        text: 'About',
    },
    {
        to: { name: 'contact' },
        text: 'Contact',
    },
    {
        to: { name: 'terms' },
        text: 'Terms',
    },
]

export default navigation
