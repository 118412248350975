import { GridRow } from '@/types/Layout/Grid'

export const bannerGrid: Array<GridRow> = [
    {
        left: 600,
        top: 60,
        boxes: 20,
    },
    {
        left: 960,
        top: 100,
        boxes: 10,
    },
    {
        left: 120,
        bottom: 80,
        boxes: 8,
    },
    {
        left: 80,
        bottom: 40,
        boxes: 8,
    },
    {
        left: 0,
        bottom: 0,
        boxes: 25,
    },
]
