export const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/pages/home.vue'),
    },
    {
        path: '/expertise',
        name: 'expertise-landing',
        component: () => import('@/pages/expertise.vue'),
    },
    {
        path: '/expertise/:slug',
        name: 'expertise',
        component: () => import('@/pages/expertise/slug.vue'),
    },
    {
        path: '/articles',
        name: 'articles',
        component: () => import('@/pages/articles.vue'),
    },
    {
        path: '/articles/:slug',
        name: 'article',
        component: () => import('@/pages/articles/slug.vue'),
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/pages/about.vue'),
    },
    {
        path: '/career',
        name: 'career',
        component: () => import('@/pages/career.vue'),
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/pages/contact.vue'),
    },
    {
        path: '/terms',
        name: 'terms',
        component: () => import('@/pages/terms.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'page-not-found',
        component: () => import('@/pages/404.vue'),
    },
    // {
    //     path: '/sitemap',
    //     name: 'sitemap',
    //     component: () => import(/* webpackChunkName: "sitemap" */ '@/components/Page/SitemapPage.vue')
    // },
]
