import { Attributes, Model } from '@/types/Model/Model'
import { Link } from 'vue-page-components/lib/types'
import { MorphComponent } from '../Page/Morphs'

export interface Page {
    id: number
    created_at: string
    updated_at: string
    published_at: string
    title: string
    slug: string
    path: string
    sort: number
    meta_title: string
    meta_description: string
    canonical: string
    robots: string
    twitter_card: string
    twitter_site: string
    og_type: string
    og_sitename: string
    og_locale: string
    priority: number
    change_frequency: string
    featured: boolean
    images: Array<string>
    parent_id: number
    parent: Page
    parents: Array<Page>
    children: Array<Page>
    featured_articles: Array<Page>
    components: Array<MorphComponent>
    breadcrumbs: Array<Link.BaseLinkProps>
    word_count: string
    preload?: Array<string>
}

export class Page extends Model {
    constructor(attributes: Attributes) {
        super(attributes)

        this.children = (this.children || []).map((page: Page) => new Page(page))
    }
}
