import { useSentryBrowser } from './useSentryBrowser'

export function useAdSense(pubId: string) {
    const { captureException } = useSentryBrowser()

    const initAdSense = async (): Promise<void> => {
        loadAdSense()
            .then(() => {
                ;(window.adsbygoogle = window.adsbygoogle || []).push({})
            })
            .catch(captureException)
    }
    const loadAdSense = () => {
        const script = document.createElement('script') // @todo inject into head
        script.async = true
        script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${pubId}`
        script.crossOrigin = 'anonymous'
        document.getElementsByTagName('head')[0].appendChild(script)

        return new Promise((res, rej) => {
            script.onload = function (e) {
                res(e)
            }
            script.onerror = function () {
                rej()
            }
        })
    }
    return {
        initAdSense,
    }
}
