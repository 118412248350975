import Prism from 'prismjs'

import 'prismjs/plugins/toolbar/prism-toolbar'
import 'prismjs/plugins/show-language/prism-show-language'
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard'
import 'prismjs/components/prism-markup-templating'
import 'prismjs/components/prism-php'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-json'

export function usePrism() {
    Prism.languages.vue = Prism.languages.html

    Prism.hooks.add('after-highlight', function (env) {
        const count = env.code.split('\n').length

        const lines: Array<string> = Array.from(Array(count).keys()).map((k: number) => {
            return `<span class="code-line-number">${k + 1}</span>`
        })

        const element = document.createElement('span')
        element.className = 'code-line-numbers'
        element.innerHTML = lines.join('')
        ;(env.element.parentNode as Element).appendChild(element)
    })
    return {
        Prism,
    }
}
