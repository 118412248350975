import { ComponentInternalInstance, computed, getCurrentInstance } from 'vue'
import { Consent } from 'vue-page-components/lib/types'
import { useStore } from './useStore'

export function useApp() {
    const { appStore, appState } = useStore()

    const instance: ComponentInternalInstance = getCurrentInstance() as ComponentInternalInstance

    const pageDepth = computed(() => appState.pageDepth)
    const loading = computed(() => appState.loading)
    const menu = computed(() => appState.menu)
    const wireframe = computed(() => appState.wireframe)
    const consent = computed(() => appState.consent)

    const enableAnimation = () => process.env.VUE_APP_ANIMATIONS_ENABLED === 'true'
    const enableAdSense = () => process.env.VUE_APP_ADSENSE === 'true'

    const toggleMenu = (v: boolean | undefined = undefined) =>
        (appStore.menu = v === undefined ? !menu.value : v)
    const toggleLoading = (loading: boolean) => (appStore.loading = loading)
    const toggleWireframe = () => (appStore.wireframe = !wireframe.value)
    const incrementPageDepth = () => (appStore.pageDepth = pageDepth.value + 1)

    const setConsentState = (consent: Consent.Cookie | null) => (appStore.consent = consent)

    const iconComponentName = (key: string) =>
        'Icon' + key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()

    return {
        instance,
        menu,
        pageDepth,
        loading,
        wireframe,
        consent,
        enableAnimation,
        enableAdSense,
        toggleMenu,
        toggleLoading,
        toggleWireframe,
        incrementPageDepth,
        setConsentState,
        iconComponentName,
    }
}
