export function useUrl() {
    const url = (path: string): string =>
        `${(process.env.VUE_APP_BASE_URL || '').slice(0, -1)}${normalize(path)}`

    const imageUrl = (path: string): string =>
        `${(process.env.VUE_APP_IMAGE_URL || '').slice(0, -1)}${normalize(path)}`

    const imageUrls = (paths: Array<string>): Array<string> =>
        (paths || []).map((path: string) => imageUrl(path))

    const normalize = (path: string) => (path.slice(0, 1) === '/' ? path : `/${path}`)

    return {
        url,
        imageUrl,
        imageUrls,
        normalize,
    }
}
